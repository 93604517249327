import { Form as AForm } from "antd";
import FormBuilder from "antd-form-builder";
import Axios from "axios";
import React from "react";
import { LeftPanel } from "./LeftPanel";
import { Loader } from "./Loader";
import { LoaderComponent } from "./LoaderComponent";
import { ErrorModal, SuccessModal } from "./SuccessModal";

export const Form = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [errVisible, setError] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [form] = AForm.useForm();

  const handleSubmit = async (values) => {
    setLoading(true);
    window.gtag("event", `Submit`, {
      method: "Google",
      data: values.name,
    });
    values.form_name = details.url_name;
    try {
      await Axios.post(props.URL, values);
      setVisible(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      throw error;
    }
  };

  const promise = async () => {
    props.promise().then(res => {
      setDetails(res);
    })
  };

  const handleOk = () => {
    setVisible(false);
    setDisabled(true);
    form.resetFields();
    window.location.href = "https://careers.coditas.com/";
  };

  const handleError = (e) => {
    setError(false);
    form.resetFields();
  };

  const renderContent = () => {
    return (
      <div className={`form-container ${props.containerClass}`}>
        {!props.isFeedback && <LeftPanel data={details} />}
        <div className={`form-inputs ${props.inputClassName}`}>
          <h3>Fill out the form</h3>
          <div className="form-top">
            <p>Help us with the following details</p>
            <div className="mandatory">* All fields are mandatory</div>
          </div>
          {isLoading && <Loader />}
          <div>
            <AForm form={form} colon={false} onFinish={handleSubmit}>
              {errVisible ? (
                <ErrorModal handleOk={handleError} visible={errVisible} />
              ) : (
                <SuccessModal handleOk={handleOk} visible={visible} />
              )}
              <div className={props.className}>
                <FormBuilder
                  disabled={disabled}
                  meta={props.meta}
                  form={form}
                />
              </div>
              <div className="bottom btm">
                <button
                  className="form-button"
                  disabled={disabled}
                  type="primary"
                >
                  {props.label}
                </button>
              </div>
              <div className="desc" >
                By filling out this form, you consent to receive the latest updates about upcoming events, news, and industry trends from Coditas, and rest assured; we won't flood your inbox with spam ;)
              </div>
            </AForm>
          </div>
        </div>
      </div>
    );
  };
  return <LoaderComponent promise={promise} content={renderContent()} />;
};
