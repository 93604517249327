import * as React from "react";
import "./loader.scss";

export class Loader extends React.PureComponent {
  render() {
    return (
      <div className="loading-container">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {this.props.children}
      </div>
    );
  }
}
