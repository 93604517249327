import React from "react";
import "./navbar.scss";
import { Link, useHistory } from "react-router-dom";
import logo from "./Coditas.png";

export const Navbar = (props) => {
  const handleLocation = (URL) => {
    window.location.href = URL;
  };
  const [isOpen, setOpen] = React.useState(false);
  const [isBottom, setBottom] = React.useState(false);

  const history = useHistory();

  // const isBottom = props.position > 530;
  React.useEffect(() => {
    window.onscroll = () => {
      const scrollPos = window.scrollY;
      if (scrollPos > 530) {
        setBottom(true);
      } else {
        setBottom(false);
      }
    };
  });
  return (
    <div className={`navbar-container ${isBottom ? "white" : "blue"}`}>
      <div className="nav-inner">
        <img
          onClick={() => history.push("/")}
          className="image-logo"
          src={props.logo || logo}
          alt="logo"
        />
        <div
          onClick={() => setOpen(!isOpen)}
          className={isOpen ? "open" : ""}
          id="nav-icon1"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul
          className={`${
            isOpen ? "navbar-mobile" : "navbar-row"
          } navbar-hidden `}
        >
          <li
            onClick={() => handleLocation("https://coditas.com")}
            className="nav-item"
          >
            Home
          </li>
          <li
            onClick={() => handleLocation("https://coditas.com/#about")}
            className="nav-item"
          >
            About Us
          </li>
          <li
            onClick={() => handleLocation("https://coditas.com/#services")}
            className="nav-item"
          >
            Services
          </li>
          <li
            onClick={() => handleLocation("https://coditas.com/#portfolio")}
            className="nav-item"
          >
            Portfolio
          </li>
          <li
            onClick={() => handleLocation("https://careers.coditas.com/")}
            className="nav-item"
          >
            Careers
          </li>
          <li
            onClick={() => <Link to="/featured-banner"></Link>}
            className="nav-item active"
          >
            Events
          </li>
          <li
            onClick={() => handleLocation("https://coditas.com/#contact-us")}
            className="nav-item"
          >
            Contact Us
          </li>
        </ul>
      </div>
    </div>
  );
};
