import "antd/dist/antd.css";
import React from "react";
import EventNav from "../reusableComponents/EventNav";
import { Form } from "../reusableComponents/Form";
import meta from "./metaData.json";

export const FeedbackForm = (props) => {
  React.useEffect(() => {
    document.title = `Webinar's Feedback`;
  });

  return (
    <>
      <div className="fixed-top eventnav">
        <EventNav
          {...props}
          isForm
          title="Feedback on 'React and Angular Bootcamp'"
        />
      </div>
      <div className="form-content">
        <Form
          containerClass="feedback-form"
          promise={() => new Promise(res => res())}
          inputClassName="feedback"
          className="form-out"
          label={"Submit Feedback"}
          isFeedback={true}
          meta={meta}
          URL={
            "https://us-central1-coditas-website.cloudfunctions.net/saveFeedback"
          }
        />
      </div>
    </>
  );
};

export const FeedbackFormNext = (props) => {
  React.useEffect(() => {
    document.title = `Webinar's Feedback`;
  });

  return (
    <>
      <div className="fixed-top eventnav">
        <EventNav
          {...props}
          isForm
          title="UX Writing: Designing better experiences with words'"
        />
      </div>
      <div className="form-content">
        <Form
          containerClass="feedback-form"
          inputClassName="feedback"
          className="form-out"
          label={"Submit Feedback"}
          isFeedback={true}
          promise={() => new Promise(res => res())}
          meta={meta}
          URL={
            "https://us-central1-coditas-website.cloudfunctions.net/saveFeedbackNext"
          }
        />
      </div>
    </>
  );
};
