import React from "react";
import { Calender, Clock } from "../icon";
// import "../event.scss";

export const EventDate = (props) => {
  return (
    <div className="calender datetime">
      <Calender />
      &nbsp;
      <span>{props.EventDate}</span>
    </div>
  );
};
export const EventTime = (props) => {
  return (
    <div className="clock datetime">
      <Clock className="Clocksvg" />
      &nbsp;
      <span>{props.EventTime}</span>
    </div>
  );
};

export const Title = (props) => {
  return <h1 className={`title ${props.className}`}>{props.title}</h1>;
};

export const AboutEvent = (props) => {
  return <h3 className="eventtitle">{props.aboutEvent}</h3>;
};
