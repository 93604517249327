import "antd/dist/antd.css";
import React, { useEffect } from "react";
// import "react-perfect-scrollbar/dist/css/styles.css";
import axios from "axios";
import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import Slider from "react-slick";
import "./App.scss";
import { FeedbackForm, FeedbackFormNext } from "./components/FeedbackForm";
import { RegistrationForm } from "./components/RegistrationForm";
const settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
};

export const SimpleSlider = (props) => (
  <Slider {...settings} {...props}>
    {props.children}
  </Slider>
);

const WithScroll = (props) => {
  const Component = props.comp;
  return <Component {...props} />;
};



function App() {
  const [eventsData, setEventsData] = React.useState([])
  const getEventsData = () => {
    axios.get('https://cms.coditas.com/cms/v1/get-content?name=events').then(res => {
      setEventsData(res.data.data)
    }).catch(err => {
      throw err
    })
  }

  useEffect(() => {
    getEventsData()
  }, [])

  return (
    <BrowserRouter>
      <Route
        render={({ location }) => {
          return (
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  window.location.href = "https://coditas.com"
                )}
              />
              {eventsData.map((event => {
                return <Route
                  exact
                  path={`/event-registration/:url_name`}
                  render={() => (
                    <>
                      <WithScroll eventsData={eventsData} comp={withRouter(RegistrationForm)} />
                    </>
                  )}
                />
              }))}
              <Route
                exact
                path="/event-feedback"
                render={() => <WithScroll comp={FeedbackForm} />}
              />
              <Route
                exact
                path="/next-event-feedback"
                render={() => <WithScroll comp={FeedbackFormNext} />}
              />
            </Switch>
          );
        }}
      />
    </BrowserRouter>
  );
}

export default App;
