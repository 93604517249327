import React from "react";
import "../Button/button.scss";
export const Button = (props) => {
  return (
    <div className="button-container">
      <button {...props} className={props.className} type="primary">
        {" "}
        {props.label}
      </button>
    </div>
  );
};

