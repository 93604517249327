/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./loader.scss";
export const LoaderComponent = (props) => {
  const [isLoading, setLoading] = React.useState(true);
  const [hasError, setError] = React.useState(false);

  React.useEffect(() => {
    const callApi = async () => {
      if (props.promise) {
        try {
          await props.promise();
          setLoading(false);
        } catch (error) {
          setError(true);
          setLoading(false);
          throw error;
        }
      }
    };
    callApi();
  }, [""]);

  if (isLoading) {
    return (
      <div className="load-wrapp">
        <div className="load-7">
          <div className="square-holder">
            <div className="square"></div>
            <div className="square"></div>
            <div className="square"></div>
          </div>
        </div>
      </div>
      // <Loader />
    );
  }
  if (hasError) {
    return <h1>Error</h1>;
  }
  if (!isLoading && !hasError) {
    return <div>{props.content}</div>;
  }
};
