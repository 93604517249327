import React from "react";

export const Calender = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.75"
        y="2.75"
        width="18.5"
        height="18.5"
        rx="3.25"
        stroke="#0B0B3C"
        strokeWidth="1.5"
      />
      <rect x="8" width="2" height="3" rx="1" fill="#0B0B3C" />
      <rect x="14" width="2" height="3" rx="1" fill="#0B0B3C" />
      <rect x="6.5" y="8" width="3" height="3" rx="1.5" fill="#0B0B3C" />
      <rect x="10.5" y="8" width="3" height="3" rx="1.5" fill="#0B0B3C" />
      <rect x="14.5" y="8" width="3" height="3" rx="1.5" fill="#0B0B3C" />
      <rect x="6.5" y="12" width="3" height="3" rx="1.5" fill="#0B0B3C" />
      <rect x="10.5" y="12" width="3" height="3" rx="1.5" fill="#0B0B3C" />
      <rect
        opacity="0.2"
        x="14.5"
        y="12"
        width="3"
        height="3"
        rx="1.5"
        fill="#0B0B3C"
      />
    </svg>
  );
};

export const Clock = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.75"
        y="2.75"
        width="18.5"
        height="18.5"
        rx="9.25"
        stroke="#0B0B3C"
        strokeWidth="1.5"
      />
      <rect
        x="7.52544"
        y="9.12272"
        width="0.75"
        height="4.97591"
        rx="0.375"
        transform="rotate(-52.7877 7.52544 9.12272)"
        stroke="#0B0B3C"
        strokeWidth="1.5"
      />
      <rect
        x="11.9765"
        y="12.4907"
        width="0.75"
        height="7.26138"
        rx="0.375"
        transform="rotate(-126.964 11.9765 12.4907)"
        stroke="#0B0B3C"
        strokeWidth="1.5"
      />
      <rect x="10" y="10" width="4" height="4" rx="2" fill="#0B0B3C" />
    </svg>
  );
};
