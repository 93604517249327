import React from "react";
import "./footer.scss";
import logo from "../Navbar/Coditas.png";
const footer = [
  {
    title: "Pages",
    children: [
      {
        key: 1,
        name: "About Us",
        href: "",
      },
      {
        key: 2,
        name: "Careers",
        href: "",
      },
      {
        key: 3,
        name: "Portfolio",
        href: "",
      },
      {
        key: 4,
        name: "Sitemap",
        href: "",
      },
    ],
  },
  {
    title: "In Media",
    children: [
      {
        key: 1,
        name: "YourStory",
        href: "",
      },
      {
        key: 2,
        name: "Business Insider",
        href: "",
      },
      {
        key: 3,
        name: "NDTV Business",
        href: "",
      },
      {
        key: 4,
        name: "Startup Charter",
        href: "",
      },
    ],
  },
  {
    title: "Social Media",
    children: [
      {
        key: 1,
        name: "LinkedIn",
        href: "",
      },
      {
        key: 2,
        name: "Instagram",
        href: "",
      },
      {
        key: 3,
        name: "Facebook",
        href: "",
      },
      {
        key: 4,
        name: "Twitter",
        href: "",
      },
    ],
  },
  {
    title: "Resources",
    children: [
      {
        key: 1,
        name: "Design Boilerplate",
        href: "",
      },
      {
        key: 2,
        name: "Company Brochure",
        href: "",
      },
      {
        key: 3,
        name: "Media Kit",
        href: "",
      },
    ],
  },
];

export const Footer = () => {
  const [expandKey, setKey] = React.useState(null);
  return (
    <div className="background">
      <div className="footer-container">
        <div className="footer-content">
          {footer.map((child) => {
            const isOpen = expandKey === child.title;
            return (
              <div className="column" key={child.title}>
                <h3
                  onClick={() =>
                    expandKey ? setKey(null) : setKey(child.title)
                  }
                >
                  {child.title}
                  <ArrowBottom
                    isOpen={isOpen}
                    onClick={() =>
                      expandKey ? setKey(null) : setKey(child.title)
                    }
                  />
                </h3>
                <ul className={`list-item ${isOpen ? "expanded" : "collapse"}`}>
                  {child.children.map((data) => {
                    return <li key={data.name}>{data.name}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <div className="branding">
          <div className="coditas">
            <img alt="coditas-logo" src={logo} />
            <div>Code is art, Code is us</div>
          </div>
          <div className="copyright">
            © 2021 Coditas Technologies Pvt. Ltd. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

const ArrowBottom = (props) => {
  return (
    <i
      onClick={props.onClick}
      className={`arrow ${props.isOpen ? "right" : "down"}`}
    />
  );
};
