import React from "react";
import { Button } from "../Button";
import { NavEvent } from "../EventNavbar/reusableprops";
import { Navbar } from "../Navbar";
import logo from "./Coditas.png";
import "./eventNav.scss";
import { useHistory } from "react-router-dom";
import arrow from './arrow.svg'
const EventNav = (props) => {
  const [isBottom, setBottom] = React.useState(false);

  React.useEffect(() => {
    window.onscroll = () => {
      const scrollPos = window.scrollY;
      if (scrollPos > 50) {
        setBottom(true);
      } else {
        setBottom(false);
      }
    };
  });
  const history = useHistory();
  return (
    <div className={isBottom ? "flex" : "block"}>
      <Navbar logo={logo} />
      <div className="newcontainer">
        <div className={`arrow-event ${isBottom ? "nav-top" : "nav-bottom"}`}>
          {/* <img
            className="hover"
            onClick={() => history.goBack()}
            src={arrow}
            alt="Arrow"
          /> */}
          <NavEvent navEvent={props.title} />
        </div>
        <div className="butt-div">
          {!props.isForm && (
            <Button
              onClick={() =>
                history.push(`/event-registration/${props.match.params.id}`)
              }
              className="blue-button"
              label="Reserve your seat"
            ></Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventNav;
