const options1 = ["Founder", "Budding Entrepreneur"];
const options2 = ["Social Media", "Email", "Event Listing Site", "Word of Mouth", "Others"];
// const workOptions = ["0-1 year", "1-3 years", "3-5 years"];

export const meta = [
  {
    key: "name",
    label: "Full Name",
    hasFeedback: true, // Show validation status icon in the right
    placeholder: "Your Full Name",
    rules: [
      // This is equivalent with "required: true"
      {
        required: true,
        message: "This is a required question",
      },
    ], // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
  },
  {
    key: "email",
    label: "Official Email Address",
    hasFeedback: true, // Show validation status icon in the right
    placeholder: "Your Email",
    rules: [
      // This is equivalent with "required: true"

      {
        type: "email",
        message: "Invalid Email",
      },
      {
        required: true,
        message: "This is a required question",
      },
    ], // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
  },
  {
    type: "number",
    key: "mobile",
    label: "Contact Number",
    hasFeedback: true, // Show validation status icon in the right
    placeholder: "Your Phone Number (Optional)",
    rules: [
      // This is equivalent with "required: true"
      {
        message: "This is a required question",
      },
    ], // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
  },
  {
    key: "organization",
    label: "Company Name",
    hasFeedback: true, // Show validation status icon in the right
    placeholder: "Your Company Name",
    rules: [
      // This is equivalent with "required: true"
      {
        required: true,
        message: "This is a required question",
      },
    ], // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
  },
  {
    key: "jobTitle",
    label: "Designation",
    hasFeedback: true, // Show validation status icon in the right
    placeholder: "Your Designation",
    rules: [
      // This is equivalent with "required: true"
      {
        required: true,
        message: "This is a required question",
      },
    ], // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
  },
  {
    className: 'm-5',
    key: "isAvailableForEvent",
    label: "Are you a founder or a budding entrepreneur?",
    widget: "radio-group",
    forwardRef: true,
    options: options1,
    rules: [
      // This is equivalent with "required: true"
      {
        required: true,
        message: "This is a required question",
      },
    ],
  },

  {
    type: "textarea",
    key: "desc",
    label: "Briefly describe your company or startup (100 words)",
    hasFeedback: true, // Show validation status icon in the right
    placeholder: "Description",
    rules: [
      // This is equivalent with "required: true"
      {
        required: true,
        message: "This is a required question",
      },
    ], // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
  },
  {
    type: "textarea",
    key: "topics",
    label: "What specific topics you'd like to discuss or learn about?",
    hasFeedback: true, // Show validation status icon in the right
    placeholder: "Topics",
    rules: [
      // This is equivalent with "required: true"
      {
        required: true,
        message: "This is a required question",
      },
    ], // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
  }, {
    key: "hearedFrom",
    label: "How did you hear about this event?",
    widget: "select",
    placeholder: "Heared From",
    forwardRef: true,
    options: options2,
    rules: [
      // This is equivalent with "required: true"
      {
        required: true,
        message: "This is a required question",
      },
    ],
  },
];
