import React, { useEffect } from "react";
import EventNav from "../reusableComponents/EventNav";
import { Footer } from "../reusableComponents/Footer";
import { Form } from "../reusableComponents/Form";
import { meta } from "./formConst";
import './index.scss'

export const RegistrationForm = (props) => {
  // const [eventData, setEventData] = useState({})
  useEffect(() => {
    const event = props.eventsData.find(x => x.url_name === props.match.params.url_name) || {}
    document.title = `${event.title} - Coditas Event`
    const ogImage = document.getElementById("og-image")
    const ogTitle = document.getElementById("og-title")
    const ogDecription = document.getElementById("og-decription")
    if (event.og_banner_url) {
      ogImage.setAttribute('content', event.og_banner_url)
    }
    if (event.og_title) {
      ogTitle.setAttribute('content', event.og_title)
    }
    if (event.og_description) {
      ogDecription.setAttribute('content', event.og_description)
    }
    console.log('OG:IMAGE', ogImage);
    console.log('OG:TITLE', ogTitle);
    console.log('OG:DESCRIPTION', ogDecription);
    // setEventData(event)
  }, [props])

  const getEventData = async () => {
    const event = props.eventsData.find(x => x.url_name === props.match.params.url_name)
    return event
  };

  return (
    <>
      <div className="fixed-top eventnav">
        <EventNav {...props} isForm title="Reserve your seat" />
      </div>
      <div className="form-content-container">
        <div className="form-content">
          <Form
            {...props}
            promise={getEventData}
            label={"RESERVE SEAT"}
            meta={meta}
            URL={
              "https://us-central1-coditas-website.cloudfunctions.net/saveRegistration"
            }
          />
        </div>
      </div>
      <Footer />
    </>
  );
};
