import React from "react";
import { formatDate, formatTime } from "../../../services/utils";
import {
  EventDate,
  EventTime,
} from "../EventDetails/Description/reusableprops";
import "./leftPanel.scss";
import ReactMarkdown from "react-markdown";

export const LeftPanel = (props) => {
  const [isShow, setShow] = React.useState(true);
  const { data } = props;
  return (
    <div className={`left-panel`}>
      <h3
        dangerouslySetInnerHTML={{
          __html: data.title,
        }}
      ></h3>
      <div className={`presenter ${isShow ? "show" : "hide"}`}>
        {((data.form_profile_images || '').split(',') || []).map((form_profile_image, index) => {
          return <div className="p1">
            <img
              src={`${form_profile_image}`}
              width="50px"
              alt="presenter"
            />
            <div className="presenter-info">
              <p className="by">{(data.presenters || '').split(',')[index]}</p>
              <p className="desg">{(data.designations || '').split(',')[index]}</p>
            </div>
          </div>
        })}
      </div>
      <div className="schedule">
        <EventDate EventDate={formatDate(data.date)} />
        &nbsp;&nbsp;
        <EventTime EventTime={formatTime(data.time, data.time_difference)} />
      </div>
      <div className={`event-about ${isShow ? "expanded" : "collapse"}`}>
        {data.desc && <>
          <div className="event-details-form">
            <ReactMarkdown children={data.desc} />
          </div>
        </>}
      </div>
      <div onClick={() => setShow(!isShow)} className={`visibleMobile`}>
        {isShow ? "HIDE DETAILS" : "EVENT DETAILS"}
      </div>
    </div >
  );
};
