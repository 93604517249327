import moment from "moment";
const nth = function (d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const getWeekDay = (num) => {
  switch (num) {
    case 7:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
    default:
      break;
  }
};

const format = (date, format) => moment(new Date(date)).format(format);
export const formatDate = (date) => {
  return `${format(date, "DD")}${nth(format(date, "DD"))} of 
  ${format(date, "MMM")}(${getWeekDay(moment(new Date(date)).weekday())})`;
};

export const formatTime = (time, diff = 1) => {
  return `${moment(time, "HH:mm:ss").format("hh:mm a")} to ${moment(
    time,
    "HH:mm:ss"
  )
    .add(diff, "hour")
    .format("hh:mm a")}`;
};
