import { Modal, Button } from "antd";
import React from "react";
import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";
export function SuccessModal(props) {
  return (
    <Modal
      closable={false}
      visible={props.visible}
      onOk={props.handleOk}
      footer={[
        <Button key="submit" type="primary" onClick={props.handleOk}>
          DONE
        </Button>,
      ]}
    >
      <CheckOutlined className={"anticon-anticon-check-success"} />
      <h2>Congratulations!</h2>
      <span> Your response has been recorded. </span>
    </Modal>
  );
}
export function ErrorModal(props) {
  return (
    <Modal
      closable={false}
      visible={props.visible}
      onOk={props.handleOk}
      footer={[
        <Button key="submit" type="default" onClick={props.handleOk}>
          DONE
        </Button>,
      ]}
    >
      <ExclamationOutlined className={"anticon-anticon-check-failed"} />
      <h2>Sorry, something went wrong..!</h2>
      <span> Please try again. </span>
    </Modal>
  );
}
